var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-container"},[_c('table',{staticClass:"table table-condensed table-bordered table-hover dataTable table-striped table-responsive"},[_c('thead',[_c('tr',_vm._l((_vm.cols),function(col,ix_col){return _c('th',{key:ix_col,style:({
            textAlign: _vm.textAlign(col),
            color: _vm.color(col),
            backgroundColor: _vm.backgroundColor(col)
          })},[_vm._v(" "+_vm._s(col.title)+" ")])}),0)]),(_vm.equipment)?_c('tbody',_vm._l((_vm.rows),function(row,ix_row){return _c('tr',{key:ix_row},_vm._l((row),function(cell,ix_cel){return _c('td',{key:ix_cel,style:({
            textAlign: _vm.textAlign(cell),
            color: _vm.color(cell),
            backgroundColor: _vm.backgroundColor(cell),
            fontWeight: _vm.fontWeight(cell),
            fontFamily: _vm.fontFamily(cell),
            border: _vm.border(cell)
          })},[_vm._v(" "+_vm._s(_vm.value(cell))+" ")])}),0)}),0):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="table-container">
    <table
      class="table table-condensed table-bordered table-hover dataTable table-striped  table-responsive"
    >
      <thead>
        <tr>
          <th
            v-for="(col, ix_col) in cols"
            v-bind:key="ix_col"
            v-bind:style="{
              textAlign: textAlign(col),
              color: color(col),
              backgroundColor: backgroundColor(col)
            }"
          >
            {{ col.title }}
          </th>
        </tr>
      </thead>
      <tbody v-if="equipment">
        <tr v-for="(row, ix_row) in rows" v-bind:key="ix_row">
          <td
            v-for="(cell, ix_cel) in row"
            v-bind:key="ix_cel"
            v-bind:style="{
              textAlign: textAlign(cell),
              color: color(cell),
              backgroundColor: backgroundColor(cell),
              fontWeight: fontWeight(cell),
              fontFamily: fontFamily(cell),
              border: border(cell)
            }"
          >
            {{ value(cell) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "EquipmentTableDataView",
  props: {
    equipment: Object,
    display: Object,
    panelName: String
  },
  components: {},
  data() {
    return {
      runtimePerc: "-",
      nextMaintenance: "-",
      lastData: {}
    };
  },
  methods: {
    lastEquipmentData: function(data_id) {
      return (
        this.$store.getters["dashboard/dataList"].find(
          (item) => item.id == data_id && item.current_value
        ) || null
      );
    },
    value: function(cell) {
      var self = this;
      let vlr = self.lastValue(cell);
      if (cell.data_id) {
        if (vlr == "-") {
          vlr =
            cell.data_id in self.lastData ? self.lastData[cell.data_id] : vlr;
        } else {
          self.lastData[cell.data_id] = vlr;
        }
      }
      return vlr;
    },
    lastValue: function(cell) {
      var self = this;
      let fmt = "-";
      var lastEquipmentData =
        (cell.data_id && self.lastEquipmentData(cell.data_id)) || null;
      if (lastEquipmentData) {
        let vlr =
          lastEquipmentData &&
          parseFloat(lastEquipmentData.current_value.value);
        fmt = vlr;
        if ("format" in cell && cell.format) {
          fmt = this.$utils.sprintf(cell.format, vlr);
        } else if ("decimals" in cell) {
          fmt = parseFloat(vlr).toFixed(cell.decimals || 0);
        }
      } else {
        if (cell.data_id in self.equipment) {
          fmt = self.equipment[cell.data_id];
        }
      }
      return fmt;
    },
    unit: function(cell) {
      var self = this;
      var lastEquipmentData =
        (cell.data_id && self.lastEquipmentData(cell.data_id)) || null;
      if (!lastEquipmentData) return "";
      return (lastEquipmentData && lastEquipmentData.unity_label) || "";
    },
    textAlign: function(cell) {
      return cell.textAlign || "center";
    },
    backgroundColor: function(cell) {
      return cell.backgroundColor || "transparent";
    },
    color: function(cell) {
      return cell.color || "#000000";
    },
    fontFamily: function(cell) {
      return cell.fontFamily || "inherit";
    },
    fontWeight: function(cell) {
      return cell.fontWeight || "inherit";
    },
    border: function(cell) {
      return cell.border || "none";
    }
  },
  computed: {
    panelOptions: function() {
      var panel = this.panel || null;
      return (panel && panel.options) || null;
    },
    panel: function() {
      var self = this;
      var lst = ((self.display && self.display.panels) || []).filter(function(
        i
      ) {
        return i.name == self.panelName;
      });
      return (lst.length && lst[0]) || null;
    },
    cols: function() {
      var self = this;
      var options = self.panelOptions;
      return (options && options.data && options.data.cols) || [];
    },
    rows: function() {
      var self = this;
      var options = self.panelOptions;
      return (options && options.data && options.data.rows) || [];
    }
  }
};
</script>

<style scoped>
.table-container {
  overflow: auto;
}
.table-container th,
table-container td {
  vertical-align: top;
}
.table-container td {
  vertical-align: top;
  white-space: nowrap;
}
</style>
